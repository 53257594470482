import React from "react";

import { FiHexagon } from "../assect/icons/vander";
import { MdCheckCircle, MdSettings } from "react-icons/md";
import { RiUserStarFill } from "react-icons/ri";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { FaSmile } from "react-icons/fa";

export default function WhyChooseUs() {
  const whyChooseUsData = [
    {
      icon: MdCheckCircle,
      title: "Dedicated to Quality",
      desc: "We manage every aspect of property upkeep with meticulous attention, ensuring a seamless experience for both residents and landlords.",
    },
    {
      icon: RiUserStarFill,
      title: "Experienced Team",
      desc: "Our skilled team brings expertise in property management, tenant relations, and legal compliance, safeguarding your investment.",
    },
    {
      icon: AiOutlineSafetyCertificate,
      title: "Transparency and Trust",
      desc: "We believe in open communication and detailed reporting, giving landlords complete visibility into their property’s performance.",
    },
    {
      icon: FaSmile,
      title: "Customer Satisfaction",
      desc: "Our priority is tenant and landlord satisfaction, delivering a safe and comfortable living environment for residents.",
    },
    {
      icon: MdSettings,
      title: "Efficient Property Management",
      desc: "From tenant screening to maintenance, we streamline every process to ensure efficient and effective property management.",
    },
  ];

  return (
    <>
      <div className="row justify-content-center">
        <div className="col">
          <div className="section-title text-center mb-4 pb-2">
            <h4 className="title mb-3">Why Choose Us</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
              Discover what sets us apart in the world of property management.
            </p>
          </div>
        </div>
      </div>

      <div className="row g-4 mt-0 justify-content-center ">
        {whyChooseUsData.map((item, index) => {
          const Icon = item.icon;
          return (
            <div className="col-md-4" key={index}>
              <div className="position-relative features text-center mx-lg-4 px-md-1">
              
                <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                  <FiHexagon className="hexagon" />
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <Icon className="fea icon-m-md text-primary" />
                  </div>
                </div>

                <div className="mt-4">
                  <h5 className="fw-medium title text-dark fs-5">
                    {item.title}
                  </h5>
                  <p className="text-muted mt-3 mb-0">{item.desc}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
