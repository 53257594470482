import React from "react";
import { FiHome, FiBriefcase, FiKey, FiMapPin } from "react-icons/fi";
import { MdConstruction, MdLocalHospital } from "react-icons/md";
import { FaUsers, FaHandshake } from "react-icons/fa";
import { AiOutlineDollar } from "react-icons/ai";

import { FiHexagon } from "../assect/icons/vander";
export default function Services() {
  const servicesData = [
    {
      icon: FiHome,
      title: "Property Management",
      desc: "We provide full property management services, handling everything from maintenance to tenant relations and rent collection.",
    },
    {
      icon: FiBriefcase,
      title: "Real Estate Investment",
      desc: "Our team helps clients navigate real estate investments, from property selection to market analysis and ROI projections.",
    },
    {
      icon: FiKey,
      title: "Tenant Services",
      desc: "We offer comprehensive tenant services, including screening, lease agreements, and responsive maintenance support.",
    },
    {
      icon: FaUsers,
      title: "Community Engagement",
      desc: "We foster a sense of community by organizing resident events, creating communication channels, and encouraging feedback.",
    },
    {
      icon: MdConstruction,
      title: "Renovation Services",
      desc: "Our experienced team offers renovation services to upgrade properties, enhancing both value and appeal for current and future tenants.",
    },
    {
      icon: AiOutlineDollar,
      title: "Rent Collection",
      desc: "We ensure timely and secure rent collection, making the process seamless for both tenants and property owners.",
    },
    {
      icon: MdLocalHospital,
      title: "Health & Safety",
      desc: "We prioritize the health and safety of all residents by adhering to strict guidelines and ensuring all properties are in optimal condition.",
    },
    {
      icon: FaHandshake,
      title: "Trusted Partnerships",
      desc: "We believe in building long-term, trusted partnerships with property owners and tenants, ensuring mutual benefit and satisfaction.",
    },
  ];

  return (
    <>
      <div className="row justify-content-center">
        <div className="col">
          <div className="section-title text-center mb-4 pb-2">
            <h4 className="title mb-3">Our Services</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
              Explore the wide range of services we offer to our clients.
            </p>
          </div>
        </div>
      </div>

      <div className="row g-4 mt-0 justify-content-center">
        {servicesData.map((item, index) => {
          const Icon = item.icon;
          return (
            <div className="col-md-4" key={index}>
              <div className="position-relative features text-center mx-lg-4 px-md-1">
                <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                  <FiHexagon className="hexagon" />
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <Icon className="fea icon-m-md text-primary" />
                  </div>
                </div>

                <div className="mt-4">
                  <h5 className="fw-medium title text-dark fs-5">{item.title}</h5>
                  <p className="text-muted mt-3 mb-0">{item.desc}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
